<template>
  <div id="app">
    <div class="header" v-if="naved">
      <img alt="logo" class="logo" src="/img/wlogo.png"/>
      <div class="nav">
        <ul>
          <li><router-link to="/">首页</router-link></li>
          <li v-for="menu1 in menus">
            <router-link v-if="menu1.children.length == 0" :to="menu1.target||''"><a-icon :type="menu1.icon" v-if="menu1.icon"/> {{menu1.name}}</router-link>
            <a-dropdown v-else>
              <router-link :to="menu1.target||''"><a-icon :type="menu1.icon" v-if="menu1.icon"/> {{menu1.name}}</router-link>
              <a-menu slot="overlay" style="min-width: 160px;">
                <template v-for="menu2 in menu1.children">
                  <a-menu-item  v-if="menu2.children.length == 0">
                    <router-link :to="menu2.target||''"><a-icon :type="menu2.icon" v-if="menu2.icon"/> {{menu2.name}}</router-link>
                  </a-menu-item>

                  <a-sub-menu v-else>
                    <span slot="title"><a-icon :type="menu2.icon" v-if="menu2.icon"/><span>{{menu2.name}}</span></span>
                      <template v-for="menu3 in menu2.children">
                        <a-menu-item  style="min-width: 160px;">
                          <router-link :to="menu3.target||''"><a-icon :type="menu3.icon" v-if="menu3.icon"/> {{menu3.name}}</router-link>
                        </a-menu-item>
                      </template>
                  </a-sub-menu>

                </template>
              </a-menu>
            </a-dropdown>
          </li>


<!--          <li><router-link to="/expert">专家</router-link></li>-->
<!--          <li><router-link to="/client">客户</router-link></li>-->
<!--          <li><router-link to="/project">项目</router-link></li>-->
<!--          <li><router-link to="/invoice">票款</router-link></li>-->
<!--          <li>-->
<!--            <a-dropdown>-->
<!--              <a href="javascript:;">员工</a>-->
<!--              <a-menu slot="overlay" style="min-width: 160px;">-->
<!--                <a-menu-item><router-link to="/staff"><a-icon type="team" /> 员工管理</router-link></a-menu-item>-->
<!--                <a-menu-item><router-link to="/weekly/report"><a-icon type="edit" /> 填写周报</router-link></a-menu-item>-->
<!--              </a-menu>-->
<!--            </a-dropdown>-->
<!--          </li>-->
<!--          <li>-->
<!--            <a-dropdown>-->
<!--              <a href="javascript:;">系统</a>-->
<!--              <a-menu slot="overlay" style="min-width: 160px;">-->
<!--                <a-menu-item><router-link to="/role"><a-icon type="team" /> 角色管理</router-link></a-menu-item>-->
<!--                <a-menu-item><router-link to="/oplog"><a-icon type="profile" /> 操作日志</router-link></a-menu-item>-->
<!--                <a-menu-item><router-link to="/week"><a-icon type="setting" /> 周设置</router-link></a-menu-item>-->
<!--                <a-menu-item><router-link to="/affair"><a-icon type="setting" /> 事项设置</router-link></a-menu-item>-->
<!--                <a-menu-item><router-link to="/menu"><a-icon type="menu" /> 菜单设置</router-link></a-menu-item>-->
<!--                <a-menu-item><router-link to="/setting"><a-icon type="setting" /> 系统设置</router-link></a-menu-item>-->
<!--              </a-menu>-->
<!--            </a-dropdown>-->
<!--          </li>-->
        </ul>
      </div>
      <div class="usr">
        <ul>
          <li class="no">
              <a-dropdown>
                <a href="javascript:;"><a-avatar style="background:#fff;" :src="staff.avatar"/></a>
                <a-menu slot="overlay" style="min-width: 160px;">
                  <a-menu-item-group :title="staff.name">
                    <!-- <a-menu-item><router-link to="/profile"><a-icon type="user" /> 个人资料</router-link></a-menu-item> -->
                    <a-menu-item><router-link to="/avatar"><a-icon type="robot" /> 修改头像</router-link></a-menu-item>
                    <a-menu-item><router-link to="/password"><a-icon type="lock" /> 修改密码</router-link></a-menu-item>
                    <a-menu-divider />
                    <a-menu-item><a href="javascript:;" @click="logout"><a-icon type="logout" /> 退出系统</a></a-menu-item>
                  </a-menu-item-group>
                </a-menu>
              </a-dropdown>
          </li>
        </ul>
      </div>
    </div>
    <div class="mainbody">
      <a-config-provider :locale="locale">
      <router-view/>
      </a-config-provider>
    </div>
  </div>
</template>

<script>
  import zhCN from 'ant-design-vue/es/locale/zh_CN';
  export default {
    name: 'App',
    data() {
      return {
        timer: null,
        naved: false,
        locale: zhCN
      }
    },
    computed: {
      staff() {
        if (this.$store.state.staff) {
          return this.$store.state.staff;
        } else {
          return {
            avatar: '/img/avatar.png',
            name: '管理员'
          };
        }
      },
      menus() {
          return this.$store.state.menus;
      }
    },
    updated() {
      this.naved = this.$route.meta.nav !== false;
    },
    mounted() {
      this.timer = setInterval(this.kicking, 3000);
    },
    methods: {
      kicking() {
        this.$post('/heartbeat');
      },
      getPopupContainer(el, dialogContext) {
        if (dialogContext) {
          return dialogContext.getDialogWrap();
        } else {
          return document.body;
        }
      },
      logout() {
        this.$confirm('确认要退出系统吗？', ()=>{
          this.$post('/logout').then( res => {
            let ajax = res.data;
            if (ajax.code == 0) {
              this.$store.commit('signout');
              this.$router.push({ name: 'Login' });
            }
          });
        });
      },
      naving(menu1, menu2, menu3) {
        this.on = menu1.id;
        let sides = document.getElementsByClassName('side');
        sides.forEach((item)=>{
          item.style.display = 'none';
        })
      }
    }
  }
</script>
