import Vue from 'vue'

const ccc = {
    PASSPORT_CUSTOMER: 'customer',
    PASSPORT_PLATFORM: 'platform',

    STATE_DISABLE:  'disable',
    STATE_ENABLE:   'enable',
    STATE_ONLINE:   'online',
    STATE_OFFLINE:  'offline',

    STATE_PENDING:  'pending',
    STATE_TOAWARD:  'toaward',
    STATE_AWARDING:  'awarding',
    STATE_FINISH:   'finish',
    STATE_FAILIURE:   'failure',
    

    STATE_ACCEPT:   'accept',
    STATE_REJECT:   'reject',
    STATE_REVIEW:   'review',
    STATE_NORMAL:   'normal',
    STATE_CANCEL:   'cancel',

    STATE_SUCCESS:  'success',
    STATE_ASSIGN:   'assign',
    STATE_PROGRESS: 'progress',
    STATE_COMPLETE: 'complete',


    MODE_FREE: 'free',
    MODE_SHARE: 'share',
    MODE_INDIE: 'indie',

    GENDER_MALE:    'male',
    GENDER_FEMALE:  'female',

    EXPERT_EXPERT:  'expert',
    EXPERT_DOCTOR:  'doctor',
    EXPERT_PATIENT: 'patient',

    TAX_INCLUDE:    'include',
    TAX_EXCLUDE:    'exclude',

    RECEIPT_YES:    'yes',
    RECEIPT_NOT:    'not',

    POST_YES:    'yes',
    POST_NOT:    'not',

    GENDER_SECRET:  'secret',
    GENDER_MALE:    'male',
    GENDER_FEMALE:  'female',


    NUMERALS:  [ 'I', 'II', 'III', 'IV', 'V', 'VI', 'VII', 'VIII', 'IX', 'X', 'XI', 'XII' ],
    ALPHABETS: ['A','B','C','D','E','F','G','H','I','J','K','L','M','N','O','P','Q','R','S','T','U','V','W','X','Y','Z'],

    msg: ''
}


for (let k in ccc) {
    Vue.prototype[k] = ccc[k];
}

export default ccc;


