import Vue from 'vue'
import VueRouter from 'vue-router'
import Index from './views/index/Index.vue'

import store from './store.js'
import bus from './assets/bus.js'
import http from './assets/http.js'

Vue.use(VueRouter);

const router = new VueRouter({
  routes: [
    { path: '/login', meta: { sign: false, nav: false }, component: () => import('./views/Login.vue') },
    { path: '/blocked', meta: { sign: false, nav: false }, component: () => import('./views/Blocked.vue') },
    { path: '/denied', component: () => import('./views/Denied.vue') },

    { path: '/', name: 'Index', component: Index },
    { path: '/password', name: 'Password', component: () => import('./views/index/Password.vue') },
    { path: '/profile', name: 'Profile', component: () => import('./views/index/Profile.vue') },
    { path: '/avatar', name: 'Avatar', component: () => import('./views/index/Avatar.vue') },


    { path: '/sys/setting', component: () => import('./views/sys/Setting.vue') },

    { path: '/sys/login/filter', component: () => import('./views/sys/Login/Filter.vue') },
    { path: '/sys/login/personal', component: () => import('./views/sys/Login/Personal.vue') },
    { path: '/sys/login/detail/:id', component: () => import('./views/sys/Login/Detail.vue') },

    { path: '/sys/oplog/filter', component: () => import('./views/sys/Oplog/Filter.vue') },
    { path: '/sys/oplog/personal', component: () => import('./views/sys/Oplog/Personal.vue') },
    { path: '/sys/oplog/detail/:id', component: () => import('./views/sys/Oplog/Detail.vue') },

    
    { path: '/sys/menu/filter', component: () => import('./views/sys/Menu/Filter.vue') },
    { path: '/sys/menu/create', component: () => import('./views/sys/Menu/Create.vue')},
    { path: '/sys/menu/change/:id', component: () => import('./views/sys/Menu/Change.vue') },

    { path: '/sys/role/filter', component: () => import('./views/sys/Role/Filter.vue') },
    { path: '/sys/role/create', component: () => import('./views/sys/Role/Create.vue')},
    { path: '/sys/role/change/:id', component: () => import('./views/sys/Role/Change.vue') },

    { path: '/sys/staff/filter', component: () => import('./views/sys/Staff/Filter.vue') },
    { path: '/sys/staff/create', component: () => import('./views/sys/Staff/Create.vue')},
    { path: '/sys/staff/change/:id', component: () => import('./views/sys/Staff/Change.vue') },
    { path: '/sys/staff/password/:id', component: () => import('./views/sys/Staff/Password.vue') },



    { path: '/test/role/filter', component: () => import('./views/test/Role/Filter.vue') },
    { path: '/test/role/create', component: () => import('./views/test/Role/Create.vue')},
    { path: '/test/role/change/:id', component: () => import('./views/test/Role/Change.vue') },


    { path: '/test/issue/filter', component: () => import('./views/test/Issue/Filter.vue') },
    { path: '/test/issue/create', component: () => import('./views/test/Issue/Create.vue')},
    { path: '/test/issue/change/:id', component: () => import('./views/test/Issue/Change.vue') },


    { path: '/test/paper/filter', component: () => import('./views/test/Paper/Filter.vue') },
    { path: '/test/paper/detail/:id', component: () => import('./views/test/Paper/Detail.vue') },


    { path: '/test/rank/filter', component: () => import('./views/test/Rank/Filter.vue') },
    { path: '/test/rank/create', component: () => import('./views/test/Rank/Create.vue')},
    { path: '/test/rank/change/:id', component: () => import('./views/test/Rank/Change.vue') },
    { path: '/test/rank/detail/:id', component: () => import('./views/test/Rank/Detail.vue') },
    { path: '/test/rank/award/:id', component: () => import('./views/test/Rank/Award.vue') },



    { path: '/user/user/filter', component: () => import('./views/user/User/Filter.vue') },
    { path: '/user/user/detail/:id', component: () => import('./views/user/User/Detail.vue') },







    // { path: '/ext/provider/filter', component: () => import('./views/ext/Provider/Filter.vue') },
    // { path: '/ext/provider/create', component: () => import('./views/ext/Provider/Create.vue')},
    // { path: '/ext/provider/change/:id', component: () => import('./views/ext/Provider/Change.vue') },

    // { path: '/ext/verify/filter', component: () => import('./views/ext/Verify/Filter.vue') },
    // { path: '/ext/verify/detail/:id', component: () => import('./views/ext/Verify/Detail.vue') },

    // { path: '/ext/sms/filter', component: () => import('./views/ext/Sms/Filter.vue') },
    // { path: '/ext/sms/detail/:id', component: () => import('./views/ext/Sms/Detail.vue') },

    // { path: '/ext/mail/filter', component: () => import('./views/ext/Mail/Filter.vue') },
    // { path: '/ext/mail/detail/:id', component: () => import('./views/ext/Mail/Detail.vue') },


    // { path: '/ext/weixin/filter', component: () => import('./views/ext/Weixin/Filter.vue') },
    // { path: '/ext/weixin/create', component: () => import('./views/ext/Weixin/Create.vue')},
    // { path: '/ext/weixin/change/:id', component: () => import('./views/ext/Weixin/Change.vue') },


    // { path: '/ext/weixinuser/filter', component: () => import('./views/ext/WeixinUser/Filter.vue') },
    // { path: '/ext/weixinuser/detail/:id', component: () => import('./views/ext/WeixinUser/Detail.vue') },

    // { path: '/pay/gateway/filter', component: () => import('./views/pay/Gateway/Filter.vue') },
    // { path: '/pay/gateway/create', component: () => import('./views/pay/Gateway/Create.vue')},
    // { path: '/pay/gateway/change/:id', component: () => import('./views/pay/Gateway/Change.vue') },

    // { path: '/pay/channel/filter', component: () => import('./views/pay/Channel/Filter.vue') },
    // { path: '/pay/channel/create', component: () => import('./views/pay/Channel/Create.vue')},
    // { path: '/pay/channel/change/:id', component: () => import('./views/pay/Channel/Change.vue') },


    // { path: '/pay/payway/filter', component: () => import('./views/pay/Payway/Filter.vue') },
    // { path: '/pay/payway/create', component: () => import('./views/pay/Payway/Create.vue')},
    // { path: '/pay/payway/change/:id', component: () => import('./views/pay/Payway/Change.vue') },


    // { path: '/pay/payment/filter', component: () => import('./views/pay/Payment/Filter.vue') },
    // { path: '/pay/payment/detail/:id', component: () => import('./views/pay/Payment/Detail.vue') },

    // { path: '/pay/notify/filter', component: () => import('./views/pay/Notify/Filter.vue') },
    // { path: '/pay/notify/detail/:id', component: () => import('./views/pay/Notify/Detail.vue') },



    // { path: '/menu', name: 'Menu', component: () => import('./views/Menu/Index.vue') },
  ]
});


router.handler = (to, from, next) => {
  if (to.meta.sign != false) {
    if (store.state.signed) {
      next();
    } else {
      next({
        path: '/login',
        query: {redirect: to.fullPath}
      })
    }
  } else {
    next();
  }
}

router.beforeEach((to, from, next) => {
  if (store.state.launched) {
    router.handler(to, from, next)
  } else {
    //首次访问先launch返回再往下走
    http.post('/launch', {}, { overlay: false }).then(res => {
      let ajax = res.data;

      store.commit('launch', ajax.data);
      bus.emit('launched');

      router.handler(to, from, next);
    }).catch(res=>{
      router.handler(to, from, next);
    });
  }
});
router.afterEach((to, from)=>{
  $this.$title();
})


export default router
