import Vue from 'vue'
import store from '../store.js'


import '@/assets/css/core.less';
import '@/assets/css/sys.less';

//字体图标
import VueIconFont from 'vue-icon-font'
// import '@/assets/iconfont/iconfont.js'
import '@/assets/iconfont/iconfont.css'   //class version
Vue.use(VueIconFont);


import { ConfigProvider,Layout,Row,Col,Tabs,Menu,Icon,Avatar,Dropdown,PageHeader,Card,Descriptions,Upload,Tag,Divider } from 'ant-design-vue';
import { Form,FormModel,Input,Radio,Button,Checkbox,Table,Pagination,Tooltip,Empty,Badge,Select,Popconfirm,Switch } from 'ant-design-vue';
import { DatePicker, Progress,Drawer, Alert,InputNumber,Statistic, Result,message,Rate,Tree,TreeSelect ,Slider} from 'ant-design-vue';
Vue.use(ConfigProvider).use(Layout).use(Row).use(Col).use(Tabs).use(Menu).use(Icon).use(Avatar).use(Dropdown).use(PageHeader).use(Card).use(Descriptions).use(Upload).use(Tag).use(Divider);
Vue.use(Form).use(FormModel).use(Input).use(Radio).use(Button).use(Checkbox).use(Table).use(Pagination).use(Tooltip).use(Empty).use(Badge).use(Select).use(Popconfirm).use(Switch);
Vue.use(DatePicker).use(Progress).use(Drawer).use(Alert).use(InputNumber).use(Statistic).use(Result).use(Rate).use(Tree).use(TreeSelect).use(Slider);
Vue.prototype.$message = message;



//vant组件
// import '@vant/touch-emulator';
import { Toast,Dialog,Popup,Lazyload,Uploader,Image } from 'vant';
Vue.use(Toast).use(Dialog).use(Popup).use(Lazyload).use(Uploader).use(Image);

//Grid宫格组件
import { Grid, GridItem } from 'vant';

Vue.use(Grid);
Vue.use(GridItem);

//Overlay 滑块
import { Overlay } from 'vant';
Vue.use(Overlay);

import { Cell, CellGroup } from 'vant';
Vue.use(Cell);
Vue.use(CellGroup);


//分页组件
// import Paginate from 'vuejs-paginate'
// Vue.component('pager', Paginate)

// import Multiselect from 'vue-multiselect'
// Vue.component("multiselect", Multiselect)
// import 'vue-multiselect/dist/vue-multiselect.min.css'



// import uploader from 'vue-simple-uploader'
// Vue.use(uploader)


// import mavonEditor from 'mavon-editor'
// markdown-it对象：md.s_markdown, md => mavonEditor实例
//                 or
//                 mavonEditor.markdownIt
// import 'mavon-editor/dist/css/index.css'
// use
// Vue.use(mavonEditor)





//表单验证快捷方法
Vue.prototype.$valid = function(key,must,msg) {
    return [key, { rules: [{ required: must, message: msg }] }]
}


//定义页面标题
Vue.prototype.$title = function(){
    if (arguments.length) {
        const args = [...arguments]
        document.title = args.join(' - ') + ' - ' + store.state.title;
    } else {
        document.title = store.state.title;
    }
}





//因为要自定义toast，notify
//先把先本的定义到另一个方法
Vue.prototype.$$toast = Vue.prototype.$toast;
// Vue.prototype.$$notify = Vue.prototype.$notify;



Vue.prototype.$clear = function() {
    this.$$toast.clear();
    // this.$$notify.clear();
};

//toast提示
Vue.prototype.$toast = function(message, icon) {
    if (icon) {
        if (icon == 'success' || icon=='ok') {
            this.$$toast.success(message)
        } else if (icon=='danger' || icon=='fail') {
            this.$$toast.fail(message)
        } else {
            this.$$toast({
                text: message, icon: icon
            })
        }
    } else {
        this.$$toast(message)
    }
}
Vue.prototype.$notify = function(message, level) {
    level = level || 'info';
    this.$message[level](message);
}

//$notify 已经换ANTD message
// Vue.prototype.$notify = function(message, icon) {
//     if (icon) {
//         this.$$notify({
//             duration: 2000, className: 'notify-'+icon,
//             color: null, background: null, message: message,
//         })
//     } else {
//         this.$$notify({
//             duration: 2000, className: 'notify-default',
//             color: null, background: null, message: message,
//         })
//     }
// }



Vue.prototype.$alert = function(message, next) {
    this.$dialog.alert({
        title: '提示', message: message,
        // className: 'linshedialog',
        beforeClose: (action,done)=>{
            if (next) {
                next();
            }
            done();
        }
    })
}
Vue.prototype.$confirm = function(message, confirm, cancel) {
    this.$dialog.confirm({
        title: '确认操作', message: message,
        // className: 'linshedialog',
        beforeClose: (action,done)=>{
            if (action=='confirm') {
                if (confirm) {
                    confirm()
                }
            } else {
                if (cancel) {
                    cancel()
                }
            }
            done();
        }
    })
}

Vue.prototype.$loading = function(message, duration) {
    let opt = { mask: true, message: message, duration: duration || 0 }
    this.$$toast.loading(opt);
}







export default { }

