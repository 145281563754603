import Vue from 'vue'
import md5 from 'js-md5'
import Base64 from './lib/hash64'


const ID = 'kang';
const SYSTEM = 'web';
const VERSION = '1.0.0';
const CLIENT = 'sys.kangyiyang.cn';
const NUMBER = 1;



// const hash64 = new Base64('0123'+'4Aa'+'BbCcD'+'dEeFfGgHh'+'IiJjK'+'kLlMmN'+'nOoPpQqR'+'rSsTtUuV'+'vW'+'wXxY'+'yZz56789+/')
const hash64 = Base64;

const util = {
    cookie:(name, value)=>{
        if (value) {
            let days = 1000; //定义一天
            let exp = new Date();
            exp.setTime(exp.getTime() + days * 24 * 60 * 60 * 1000);
            // 写入Cookie, toGMTString将时间转换成字符串
            document.cookie = name + "=" + escape(value) + ";expires=" + exp.toISOString();
        } else {
            let arr,reg = new RegExp("(^| )" + name + "=([^;]*)(;|$)"); //匹配字段
            if (arr = document.cookie.match(reg)) {
                return unescape(arr[2]);
            }
        }
        return null;
    },
    device:()=> {
        let key = ID + '_uuid';
        let uuid = util.cookie(key);
        if (!uuid) {
            uuid = util.guid()
            util.cookie(key, uuid)
        }
        return {
            "device": uuid,
            'system': SYSTEM, 'version': VERSION,
            'client': CLIENT, 'number': NUMBER,
        }
    },
    guid:()=> {
        return Number(Math.random().toString().substr(3, 3) + Date.now()).toString(36);
    },
    md5:(s)=>{
        return md5(s);
    },
    enhash64:(s)=>{
        return hash64.encode(s)
    },
    dehash64:(s)=>{
        return hash64.decode(s)
    },
    readDataURL:(file, next)=>{
        let reader = new FileReader();
        reader.onloadend = function(){
            next(this.result)
        }
        reader.readAsDataURL(file);
    },
    timeFields:(item, fields)=>{
        let items = item.length ? item : [ item ];
        fields = fields || [ 'quote', 'contract', 'inquiry', 'confirm', 'deadline', 'begin', 'end', 'birthday', 'finished','expired', 'completed', 'renewed', 'sended', 'reviewed', 'verified', 'visited', 'changed', 'created', 'birth', 'entry' ];
        items.forEach((item)=>{
            for (let i=0; i<fields.length; i++) {
                let field = fields[i];
                if (item[field]) {
                    item[field] = new Date(item[field]);
                }
            }
        });

        return item;
    },
    timeFormatFields:(item, format, fields)=>{
        let items = item.length ? item : [ item ];
        format = format || 'yyyy-MM-dd';
        fields = fields || [ 'begin', 'end', 'birthday', 'expired', 'renewed', 'sended', 'reviewed', 'verified', 'visited', 'changed', 'created', 'confirm', 'deadline', 'inquery', 'contract','birth', 'entry' ];
        items.forEach((item)=>{
            for (let i=0; i<fields.length; i++) {
                let field = fields[i];
                if (item[field]) {
                    item[field] = new Date(item[field]).format(format);
                }
            }
        });
    },
    imageField:(items, child, fields)=>{
        items = items.length ? items : [ items ];
        fields = fields || { icon: '/img/icon.png', cover: '/img/cover.png', avatar: '/img/avatar.png', image: '/img/nothing.png' }
        items.forEach((item)=>{
            for (let field in fields) {
                if (child) {
                    if (item[child] && !item[child][field]) {
                        item[child][field] = fields[field];
                    }
                } else {
                    if (!item[field]) {
                        item[field] = fields[field];
                    }
                }
            }
        });
    },

    /**
     * 去除图片底色
     */
     removeImgBg(img, tolerance) {
        // 背景颜色  白色
        const rgba = [255, 255, 255, 255];
        // 容差大小
  
        var imgData = null;
        const [r0, g0, b0, a0] = rgba;
        var r, g, b, a;
        const canvas = document.createElement("canvas");
        const context = canvas.getContext("2d");
        const w = img.width;
        const h = img.height;
  
        canvas.width = w;
        canvas.height = h;
        context.drawImage(img, 0, 0);
        imgData = context.getImageData(0, 0, w, h);
  
        for (let i = 0; i < imgData.data.length; i += 4) {
          r = imgData.data[i];
          g = imgData.data[i + 1];
          b = imgData.data[i + 2];
          a = imgData.data[i + 3];
          const t = Math.sqrt(
            (r - r0) ** 2 + (g - g0) ** 2 + (b - b0) ** 2 + (a - a0) ** 2
          );
          if (t <= tolerance) {
            imgData.data[i] = 0;
            imgData.data[i + 1] = 0;
            imgData.data[i + 2] = 0;
            imgData.data[i + 3] = 0;
          }
        }
        context.putImageData(imgData, 0, 0);
        const newBase64 = canvas.toDataURL("image/jpg");
        return newBase64;
      },

      //对象深度复制
      deepCopy(obj) {
        var result,
          oClass = this.getClass(obj);
  
        if (oClass == "Object") result = {};
        //判断传入的如果是对象，继续遍历
        else if (oClass == "Array") result = [];
        //判断传入的如果是数组，继续遍历
        else return obj; //如果是基本数据类型就直接返回
  
        for (var i in obj) {
          var copy = obj[i];
  
          if (this.getClass(copy) == "Object") result[i] = this.deepCopy(copy);
          //递归方法 ，如果对象继续变量obj[i],下一级还是对象，就obj[i][i]
          else if (this.getClass(copy) == "Array")
            result[i] = this.deepCopy(copy);
          //递归方法 ，如果对象继续数组obj[i],下一级还是数组，就obj[i][i]
          else result[i] = copy; //基本数据类型则赋值给属性
        }
  
        return result;
      },
      
      //判断数据类型
      getClass(o) {
        return Object.prototype.toString.call(o).slice(8, -1);
      },
}














Date.prototype.format = function(fmt) {
    let o = {
        "M+" : this.getMonth()+1,                 //月份
        "d+" : this.getDate(),                    //日
        "h+" : this.getHours(),                   //小时
        "m+" : this.getMinutes(),                 //分
        "s+" : this.getSeconds(),                 //秒
        "q+" : Math.floor((this.getMonth()+3)/3), //季度
        "S"  : this.getMilliseconds()             //毫秒
    };
    if(/(y+)/.test(fmt))
        fmt=fmt.replace(RegExp.$1, (this.getFullYear()+"").substr(4 - RegExp.$1.length));
    for(let k in o)
        if(new RegExp("("+ k +")").test(fmt))
            fmt = fmt.replace(RegExp.$1, (RegExp.$1.length==1) ? (o[k]) : (("00"+ o[k]).substr((""+ o[k]).length)));
    return fmt;
}




Number.prototype.zeroize = function(n) {
    return ("0000000000000000"+this).substr(-n);
}

Number.prototype.minute = function() {
    if (this <= 0) {
        return '00:00';
    }
    return parseInt(this/60).zeroize(2) + ':' + parseInt(this%60).zeroize(2);
}

Number.prototype.length = function() {
    let units = ["B", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB", "BB"];
    let index = 0;
    let k = this;
    if (this >= 1024) {
        while (true) {
            k = k / 1024;
            index++;
            if (k < 1024) break;
        }
    }
    return k.toFixed() + units[index];
}


Number.prototype.prefix = function(n, s) {
    s = s || '0';
    return (Array(n).join(s) + this).slice(-n);
}
Number.prototype.duration = function() {
    let second = Math.ceil(this/1000);
    let minute = 0;
    let hour = 0;
    if (second > 0) {
        minute = parseInt(second/60);
        second = parseInt(second%60);
        if (minute > 60) {
            hour = parseInt(minute/60);
            minute = parseInt(minute%60);
        }
    }
    let time = '';
    if (minute == 0) {
        time += "00:" + parseInt(second);
    } else {
        time += parseInt(second);
    }
    if (minute != 0) {
        if (minute > 9) {
            if (time == 0) {
                time = parseInt(minute) + ":" + time + '0';
            } else {
                time = parseInt(minute) + ":" + time;
            }
        } else {
            time = "0" + parseInt(minute) + ":" + time;
        }
    }
    if (hour > 0) {
        time = parseInt(hour) + ":" + time;
    }

    return minute.prefix(2) + ':' + second.prefix(2)

    // return time;
}



Number.prototype.percent = function() {
    return parseFloat((this*100).toFixed(2));
}


// s为传入的字符串，dot为金额每隔三位用","或" "间隔
Number.prototype.formatMoney = function(n) {
    n = n || 2;

    let num = String(this.toFixed(n));
    let re = /(-?\d+)(\d{3})/;
    while(re.test(num)) num = num.replace(re,"$1,$2")
    return num;

    // let dot = ','
    // let str = parseFloat((this + "").replace(/[^\d\.-]/g, "")).toFixed(0) + "";
    // var l = str.split(".")[0].split("").reverse(),
    // r = str.split(".")[1];
    // if (r == undefined){
    //     r='';
    // }
    // let t = "";
    // for(let i = 0; i < l.length; i ++ )   {
    //     t += l[i] + ((i + 1) % 3 == 0 && (i + 1) != l.length ? dot : "");
    // }
    // return "￥"+t.split("").reverse().join("") + (r==''?r:("." + r));
} 






Vue.prototype.$util = util;
export default util;