<template>
  <div>
    <div class="locale">
      <h2>后台管理首页</h2>
      <ol>
        <li><router-link to="/">后台</router-link></li>
        <li>首页</li>
      </ol>
    </div>

  </div>
</template>

<script>
// @ is an alias to /src
// import HelloWorld from '@/parts/HelloWorld'

export default {
  name: 'Index',
  // components: {
  //   HelloWorld
  // },
  data() {
    return {
      count: {
        users: 0, works: 0, chars: 0, payins: 0,
      },
      latest: {
        users: [], works: [], chars: []
      },
      stats: {
      },
      item: {
        symbol:'',
      },
      
    }
  },
  computed: {
    staff () {
      return this.$store.state.staff;
    }
  },
  mounted() {
    this.request();
  },
  methods: {
    request() {
      // this.$get('/home').then(res => {
      //     let ajax = res.data;
      //     if (ajax.data) {
      //       this.count = ajax.data.count;
      //       this.latest = ajax.data.latest;
      //     }
      //   })
    },
  }
}
</script>

<style type="less" scoped>
  .row.count {
    text-align: center;
    margin: 40px 0;
  }
  .row.count img {
    height: 28px; margin-right: 8px;
  }
  .row.count h4 {
    border-left: 0; color: #555; padding-left: 0;
    font-size: 32px; font-weight: 700;
  }
  .row.count p {
    margin: 0; padding: 0;
    font-size: 14px; color: #aaa;
  }
  .row.count span {
    font-size: 16px;
  }

  .avatar.van-grid-item__icon img {
    border-radius: 100%;
  }
</style>

