import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    title: '民法典管理系统',
    launched: false,
    signed: false,
    staff: null,
    menus: [],
    filter: {},
    test: ''
  },
  mutations: {
    launch(state, data) {
      if (data.staff) {
        this.commit('signin', data.staff);
      }
      if (data.menus) {
        this.commit('menu', data.menus);
      }
      state.launched = true;
    },
    signin(state, staff) {
      if (staff) {
        if (!staff.avatar) {
          staff.avatar = '/img/avatar.png';
        }
        if (staff.user && !staff.user.avatar) {
          staff.user.avatar = '/img/avatar.png';
        }
        state.staff = staff;
        state.signed = true;
      }
    },
    signout(state) {
      state.staff = null;
      state.signed = false;
    },
    menu(state, menus) {
      state.menus = menus;
    },
    staff(state, data) {
      if (state.staff) {
        for (let k in data) {
          state.staff[k] = data[k];
        }
        if (!state.staff.avatar) {
          state.staff.avatar = '/img/avatar.png';
        }
        if (state.staff.user && !state.staff.user.avatar) {
          state.staff.user.avatar = '/img/avatar.png';
        }
      }
    },
    filter(state, filter) {
      for (let key in filter) {
        let val = filter[key];
        state.filter[key] = val;
      }
    },
  },
  actions: {
  },
  modules: {
  }
})
