import Vue from 'vue'

const bus = new Vue();

bus.on = bus.$on;
bus.off = bus.$off;
bus.once = bus.$once;
bus.emit = bus.$emit;

Vue.prototype.$bus = bus;

export default bus;

