import Vue from 'vue'
import axios from 'axios'
import util from './util.js'

// if (window.baseUrl) {
//     axios.defaults.baseURL = window.baseUrl;
// }
axios.defaults.timeout = 1000*1000;
if (window.timeout) {
    axios.defaults.timeout = window.timeout;
}
axios.defaults.withCredentials = true;
axios.defaults.headers.ajax = "axios";
axios.interceptors.request.use(function (config) {
    if (config.overlay || config.headers.overlay) {
        $this.$loading();
    }
    return config;
}, function (err) {
    // $this.$clear();
    return Promise.reject(err);
});
axios.interceptors.response.use(function (res) {
    $this.$clear();
    if (res && res.data) {
        let json = res.data;

        //请求解密
        if (json.data && typeof json.data === 'string') {
            let s = util.dehash64(json.data);
            // console.log('jjj', s);
            json.data = JSON.parse(s);
            console.log('解密', json.data);
        }

        if (res.config.headers.feedback !== false) {
            if (json && json.text) {
                if (json.code) {
                    // $this.$notify(json.text, 'danger');
                    $this.$message.error(json.text);
                } else {
                    // $this.$notify(json.text, 'success');
                    $this.$message.success(json.text);
                }
            }
        }
    }

    return res;
}, function (err) {
    $this.$clear();

    if (err && err.response) {
        if (err.config.headers.feedback !== false) {
            let json = err.response.data;

            if (json.code == 100) {
                //没有权限
                $this.$router.replace('/denied');
            } else if (json.code == 201) {
                $this.$router.replace('/login');
            } else if (json.code == 101) {
                //没有权限
                $this.$router.replace('/blocked');
            } else if (json.code == 102) {
                //登录互斥
                $this.$store.commit('signout');
                $this.$router.replace('/login?from=kicked');
            } else {
                if (json.text) {
                    $this.$message.error(json.text);
                }
            }
        }
    }
    return Promise.reject(err);
})


let http = {
    clientHeadeer: function(uri) {

        if (uri.indexOf('?') > 0) {
            uri = uri.substring(0, uri.indexOf('?'));
        }

        // console.log(uri, uuu)

        let dev = util.device();
        let strs = [
            dev.device, dev.system, dev.version, dev.client, dev.number, new Date().getTime()/1000, uri,
        ];
        let sign = util.md5(strs.join('/'))
        strs[strs.length-1] = sign;
        return util.enhash64(strs.join('/'));
    },
    request: function(method, url, data, config) {
        config = config || {};
        config.method = method;
        config.url = url;

        // console.log('ddd', typeof data)

        // if (data.forEach) {
        //     data.forEach((val, key)=>{
        //         console.log(key, typeof val, val)
        //     })
        // } else {
        //     for (let k in data) {
        //         console.log(k, typeof data[k])
        //         // if (data[k] === null) {
        //         //     delete data[k];
        //         // }
        //     }
        // }

        //是否自接口请求
        let own = false;
        if (url.startsWith("/")) {
            own = true;
            if (window.baseUrl) {
                config.url = window.baseUrl + url;
            }
        }

        const caseMethod = method.toUpperCase();
        if (caseMethod == 'GET' || caseMethod == 'DELETE') {
            config.params = data || {};
        } else {
            config.data = data || {};
        }
        config.headers = config.headers || {};

        if (own) {
            config.headers.Client = http.clientHeadeer(url);
        }
        if (config.overlay) {
            config.headers.overlay = true;
        }
        if (config.feedback) {
            config.headers.feedback = true;
        }


        return axios(config)
    },

    get: function(url, data, config) {
        return http.request('get', url, data, config);
    },
    post: function(url, data, config) {
        if (data instanceof FormData) {
            config = config || {}
            config.headers = config.headers || {}
            config.headers['Content-Type'] = 'multipart/form-data';
        }
        return http.request('post', url, data, config);
    },
    upload: function(url, data, config) {
        if (data instanceof FormData) {
            config = config || {}
            config.headers = config.headers || {}
            config.headers['Content-Type'] = 'multipart/form-data';
        }
        return http.request('post', url, data, config);
    },
    put: function(url, data, config) {
        return http.request('put', url, data, config);
    },
    delete: function(url, data, config) {
        return http.request('delete', url, data, config);
    },
    api: function(path) {
        if (window.baseUrl) {
            return window.baseUrl + path;
        }
        return path;
    },
    heading: function(uri) {
        let dev = util.device();
        let strs = [
            dev.device, dev.system, dev.version, dev.client, dev.number, new Date().getTime()/1000, uri,
        ];
        let sign = util.md5(strs.join('/'))
        strs[strs.length-1] = sign;
        return {
            Client: util.enhash64(strs.join('/'))
        }
    },
    dating: function(json) {
        //请求解密
        if (json.data && typeof json.data === 'string') {
            let s = util.dehash64(json.data);
            // console.log('jjj', s);
            json.data = JSON.parse(s);
            console.log('解密', json.data)
        }

        return json;
    }
}


Vue.prototype.$http = http;
Vue.prototype.$get = http.get;
Vue.prototype.$post = http.post;
Vue.prototype.$upload = http.upload;
Vue.prototype.$put = http.put;
Vue.prototype.$delete = http.delete;
Vue.prototype.$request = http.request;
Vue.prototype.$api = http.api;
Vue.prototype.$heading = http.heading;
Vue.prototype.$dating = http.dating;


export default http;
