import Vue from 'vue'
import router from './router'
import store from './store'
import App from './App.vue'


import '@/assets/const.js'
import '@/assets/index.js'
import '@/assets/util.js'
import '@/assets/bus.js'
import '@/assets/http.js'

Vue.config.productionTip = false;
Vue.config.devtools = true;

window.$this = new Vue({
  router, store, render: h => h(App)
}).$mount('#app')
